import React from 'react';
import './FloatingNav.css';
import {AiOutlineHome} from 'react-icons/ai';
import {BsFillTelephoneFill, BsFacebook} from 'react-icons/bs';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import {useState} from 'react';


const Nav = () => {
    const [activeNav, setActiveNav] = useState('#');
  
    return (
        <>
            <nav className="nav">
                <a href="/" onClick={() => setActiveNav('/')} className={activeNav === '/' ? 'active' : ''} style={{fontSize: "3rem", marginLeft: "1rem", color: "whitesmoke"}}>
                    <AiOutlineHome/>
                </a>

        
                <BsFillTelephoneFill className="phone-icon" minSize={30} style={{marginTop: "1.5rem", color: "whitesmoke"}}/>
                <p  style={{marginTop: "1rem", fontSize: "1.3rem", color: "whitesmoke"}}>Call</p>
                <p className="ph-number" style={{marginTop: "2.7rem", marginLeft: "-5rem", color: "whitesmoke"}}>(517) 914-0644</p>
       
                <Link to='https://solebalancereflexology.setmore.com/bookappointment'
               target="_blank">
            <button
                className='btn-large'
               >  
                 <i className="fa fa-calendar" aria-hidden="true" style={{marginLeft: "10px"}}></i>
          
            </button>
            </Link>
            </nav>
        </>
    )
}

export default Nav;