import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Profile from '../images/Mom-profile-pic.png'
import ARCB from '../images/ARCB-card.png'
import HandCert from '../images/hand-reflex-cert.png'
import FootCert from '../images/foot-reflex-cert.png'
import "./About-section.css"

function About() {
  return (
    <>
    <div className="background">
      <div className="background-blur">
    <h1 className="header" style={{fontSize: "4rem", width: "100%", padding: "3rem", color: "rgb(241, 227, 252)", zIndex: "1", textShadow: "2px 2px 3px purple"}}>
              About Janet
            </h1>

        <div className="about"  >

        <div className="about-image">
                        <img src={Profile} alt="Janet Goodell" className="about-pic" />
         
                        </div>
           
                <div className="about-text"> 
               
                   <p> Reflexologist<br /></p>
                  <p> Nationally Board Certified<br/></p> 
                   <p> ARCB </p> 
                    
                    <div className="btn" >
                      <a href="about" alt="about janet">
                          <button className="about-btn">
                            Learn More
                          </button>
                      </a>
                    </div>
                </div>
             
                </div>

    


            <div className="content">
            <h6 className="about-h2">~ Certified Reflexologist ~</h6>
         
            <div className="icons-container">
                  <div className="icons" >
                    <img src={FootCert} alt="Foot Reflexology Certificate"/>
                    <img src={HandCert} alt="Hand Reflexology Certificate"/>
                    <img src={ARCB} alt="ARCB Card"/>
                    <h3 className="icons-h3">Professional Reflexology Certificates</h3>
                  </div>

              </div>

            
            </div>
    
       

          

  


      
            </div>
    </div>


     </>
  )
}

export default About

