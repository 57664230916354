import React from 'react'
import FloatingNav from '../FloatingNav';

export default function Maps() {
  return (
    <>
<FloatingNav/>
    <div style={{background: "rgb(29, 62, 29)", maxheight: "100vh" }}>
        <div style={{border: "20px solid rgb(29, 62, 29)", background: "whitesmoke"}}>
        <h1 style={{fontFamily: "papyrus", color: "rgb(29, 62, 29)", fontSize: "2rem", paddingTop: "1rem"}}>Directions</h1>
        <p style={{textAlign: "center", fontFamily: "papyrus", color: "rgb(29, 62, 29)", fontSize: "1.5rem"}}>Sole Balance Reflexology</p>
        <p style={{textAlign: "center",  color: "rgb(29, 62, 29)"}}>135 E Main St Suite 209</p>
        <p style={{textAlign: "center",  color: "rgb(29, 62, 29)", paddingBottom: "2rem"}}>Manchester, MI 48158</p>
        </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2958.042186727127!2d-84.04169692423723!3d42.14938404838616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883cc392c06d199d%3A0xfad654340f665a42!2s135%20E%20Main%20St%20Suite%20209%2C%20Manchester%2C%20MI%2048158!5e0!3m2!1sen!2sus!4v1692119546865!5m2!1sen!2sus"
            style={{width: "100vw",  height: "60vh", referrerpolicy:"no-referrer-when-downgrade", allowfullscreen:"", loading: "lazy", border: "20px solid rgb(29, 62, 29)", borderBottom: "40px solid rgb(29, 62, 29)" }}>
            </iframe>
    </div>
    </>
  )
}