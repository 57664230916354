import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

const Particle = () => {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (
        <Particles   
      
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fullScreen: { enable: false },
          
            
                fpsLimit: 60,
                particles: {
                  number: {
                    value: 150,
                    density: {
                      enable: true,
                      value_area: 500
                    }
                  },
                  color: {
                    value: "#ffff00", 
                  },
                  shape: {
                    type: "circle",
                    stroke: {
                      width: 0,
                      color: "#000000"
                    },
                    polygon: {
                      nb_sides: 5
                    },
                    image: {
                      src: "https://cdn.matteobruni.it/images/particles/github.svg",
                      width: 100,
                      height: 100
                    }
                  },
                  opacity: {
                    value: 0.5,
                    random: true,
                    anim: {
                      enable: true,
                      speed: .5,
                      opacity_min: 0.1,
                      sync: false
                    }
                  },
                  size: {
                    value: 3,
                    random: true,
                    anim: {
                      enable: true,
                      speed: 10,
                      size_min: 0.1,
                      size_max: 6,
                      sync: false
                    }
                  },
                  line_linked: {
                    enable: true,
                    distance: 150,
                    color: "transparent",
                    opacity: 0.4,
                    width: 1
                  },
                  move: {
                    enable: true,
                    speed: .5,
                    direction: "none",
                    random: false,
                    straight: false,
                    out_mode: "out",
                    attract: {
                      enable: false,
                      rotateX: 600,
                      rotateY: 1200
                    }
                  },
                  twinkle: {
                    particles: {
                      enable: true,
                      color: "#ffff00",
                      frequency: 0.03,
                      opacity: 1
                    },
                    lines: {
                      enable: true,
                      color: "#ff0000",
                      frequency: 0.005,
                      opacity: 1
                    }
                  }
                },
                interactivity: {
                  detect_on: "canvas",
                  events: {
                    onhover: {
                      enable: true,
                  
                      mode: "repulse",
                    },
                    onclick: {
                      enable: true,
                      mode: "bubble",
                 
                    },
                    resize: true
                  },
                  modes: {
                    grab: {
                      distance: 400,
                      line_linked: {
                        opacity: 1
                      }
                    },
                    bubble: {
                      distance: 300,
                      size: 6,
                      duration: 6,
                      opacity: 0.5,
                      speed: 3
                    },
                    repulse: {
                      distance: 200
                    },
                    push: {
                      particles_nb: 4
                    },
                    remove: {
                      particles_nb: 2
                    }
                  }
                },
                retina_detect: true,
            
              }}
              />);
            }

              export default Particle;

/*
                fpsLimit: 120,
               
                interactivity: {
                
                    events: {
                        onClick: {
                            enable: true,
                            mode: "repulse",
                        },
                     
                        onHover: {
                            enable: true,
                            mode: "bubble",
                         
                        },
                        resize: true,
                    },
                    modes: {
                      bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 2.5,
                      },
                      push: {
                        quantity: 4,
                    },
                        repulse: {
                          distance: 200,
                          duration: 0.4,
                      },
                  
                    },
                },
             
                particles: {
               
                    color: {
                        value: ["#FFFFFF", "#FFFFOO", "#FBB124"],  
                      
                    },
                   
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                          default: "bounce",
                      },
                        random: false,
                        speed: .5,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 2000,
                        },
                        value: 100,
                    },
                    opacity: {
                        value: 0.5,
                    },
                  
                
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: .5, max: 2 },
                    },
                   
                },
             
                detectRetina: true,
            }}
        />
    );
};*/

