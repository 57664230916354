import React from 'react'

import Room from '../images/moms-room.png'
import Room2 from '../images/Room2.png'

import Pic2 from '../images/2nd-pic.png'
import Pic4 from '../images/pic4.png'
import Pic5 from '../images/pic5.png'
import Pic7 from '../images/pic7.png'
import Pic8 from '../images/pic8.png'

import Arrow from '../images/green-arrow.png'
import LeftArrow from '../images/green-arrow-left.png'

import './Scroll.css'


function Scroll() {
  return (
    <>
    <div className="wrapper">
    

    <img className="scroll-img" src ={Pic2}  />
           <img className="scroll-img" src ={Pic4}/>
         <img className="scroll-img" src ={Pic7}/>
         <img className="scroll-img" src ={Room}/>
         <img className="scroll-img" src ={Pic5}/>
         <img className="scroll-img" src ={Pic8}/>
         <img className="scroll-img" src ={Room2}  />
  
    </div>
    <div className="arrow-div">
        <img className="arrow" src ={LeftArrow}/>
         view more
        <img className="arrow" src ={Arrow}/>
   </div>
    </>
  )
}

export default Scroll
