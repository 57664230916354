import React from 'react';
import Navbar  from '../src/components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home'
import Reflexology from './components/pages/Reflexology';
import About from './components/pages/About';
import Blog from './components/pages/Blog';
import Maps from './components/pages/Maps';

function App() {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/reflexology"  element={<Reflexology/>}/>
        <Route path="/about"  element={<About/>}/>
        <Route path="/blog"  element={<Blog/>}/>
        <Route path="/maps"  element={<Maps/>}/>
      </Routes>
      </Router>
    </>
  );
}

export default App;
