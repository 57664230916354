import React from 'react'
import './Footer.css'

import BG from '../images/bg.mov';
import Particle from "./Fireflies";
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { BsFacebook, BsFillTelephoneFill} from 'react-icons/bs';

import {Link} from 'react-router-dom';


function Footer() {
  return (
   <>
 
   <div className="footer-container" >
 

            <div className="footer__copyright">
        
    <div className="cards__container" >
      <div className="cards__wrapper">

        <ul className="foot-ul">

          <li className="foot-li" >
        
            <div className="cards__item__info">
   
            <h4 className="cards__item__header" style={{color: "white", marginBottom: "1rem", fontSize: "2rem"}}>Address</h4>
            <p >135 E Main St Suite 209</p>
          <p style={{marginBottom: "2rem"}}>Manchester, MI 48158</p>
          <Link  to='/maps' className="cards__item__button" style={{fontSize: "1rem",  textDecoration: "none"}}>                  
              Get Directions
          </Link>
          </div>
    
          </li>
     
    
          <li className="foot-li" >
            <div className="cards__item__info">
        
            <h4 className="cards__item__header" style={{color: "white", marginBottom: "1rem", fontSize: "2rem"}}>Contact</h4>

        <h2 >
          Call / Text
        </h2>
                    <h2 style={{ fontSize: "1rem"}}>+1 (517) 914-0644</h2>
                    <h2 style={{marginTop: "1rem", fontSize: "1.4rem"}}>Email</h2>
                    <h2 style={{fontSize: "1rem"}}>janetgsolebalance@gmail.com</h2>
                
</div>
</li>

<li className="foot-li" >
            <div className="cards__item__info">
            <h4  style={{color: "white", fontSize: "2rem", marginBottom: "1rem"}}>Facebook</h4>
                
          
               <a href="https://www.facebook.com/profile.php?id=100087936704492" target="_blank" style={{ textDecoration: "none"}}>
                  <BsFacebook className="facebook-icon" size={40}/>
                  <p style={{marginLeft: "3rem", marginTop: "-1rem", color: "white", marginBottom: "1rem"}}>
                  Visit
                  </p>
               </a>
                

              <FacebookShareButton
                url={'https://www.facebook.com/profile.php?id=100087936704492'}
                quote={'Sole Balance Reflexology'}
                hashtag="#reflexology"
              >
              <FacebookIcon size={45} round />
              Share
              </FacebookShareButton>
      
          </div>
          </li>
       
                </ul>
          </div>
          </div>
          <Particle/>
     
                <div style={{ textAlign: "center", marginBottom: "11rem"}}>
                    <small> copyright &copy; Jennifer Gerred 2023 All right reserved.</small>
                    </div>
                
              </div>
             
             
 </div>

   </>
  )
}

export default Footer
