import React from 'react'
import CardItem from './CardItem'
import './Cards.css'
import Portrait from '../images/mom-portrait.png'
import BlogPic from '../images/blog-pic.png'
import BCard from '../images/full-business-card.png'
import Room from '../images/moms-room.png'
import FB from '../images/facebook-pic.png'
import ARCBPro from '../images/ARCB-pro.png'
import ARCBEthics from '../images/ARCB-ethics.png'


function Cards() {
  return (
   <>
   <div className="cards">
    <h1  style={{fontSize: "4rem", width: "100%", paddingTop: "3rem", color: "rgb(31, 86, 31)", zIndex: "1", textShadow: "2px 2px 3px #483C32", marginBottom: "-5rem"}}>Blog</h1>
  
    <div className="cards__container">
      <div className="cards__wrapper">



        <ul className="cards__items">
           <CardItem
             src={Room}
             header="Welcome to my Reflexology business."
             text=" I am Nationally certified with ARCB
             We are taught deep thumb walking techniques over reflexes, on the plantar, dorsal, medial, and lateral aspects of your feet. Reflexology helps you to relax, increases circulation, and helps your body reboot or balance. "
             label="Welcome"
             path="/reflexology"
             button="What is Reflexology?"
           
           />
             <CardItem
             header="ARCB"
           src={BlogPic}
           text="'' Isn’t reflexology just a fancier name for foot massage? Aren’t they just one and the same thing? The answer is a very emphatic no! 
           Unfortunately, the terms “reflexology” and “foot massage” are often used interchangeably by the public, massage therapists, and even some reflexologists, but the fact of the matter is reflexology and massage are two very distinct disciplines with their own definitions, scopes of practice, and techniques.
           This article can be found in the Summer 2019 Journal located on ARCB.net on the Resources tab ''
           "
           label="arcb.net"
           path="https://www.arcb.net/resources"
           button="ARCB"
           
           />
               </ul>

       <ul className="cards__items">
             <CardItem
           src={BCard}
       
           text="Foot Reflexology session👣🦶👣Open Thursdays. 50 minute session $45"
           label="Book Now"
           path="https://solebalancereflexology.setmore.com/bookappointment"
           button="Schedule Now"
           
           />
                <CardItem
           src={FB}
       
           text="Local business located downtown Manchester, MI.
           Open Thursdays. Very flexible! If the available times aren't convenient for you please call to shcedule an appointment. Call Janet 517-914-0644.
           You can also email janetgsolebalance@gmail.com or send a facebook message to schedule your reflexology session."
           label="Contact Janet"
           path="https://www.facebook.com/profile.php?id=100087936704492"
           button="Visit Facebook"
           
           />
 </ul>
    


    


<ul className="cards__items" >
<li className="cards__item">
     <div className="cards__item__link">
            <figure className="cards__item__pic-wrap" data-category="ARCB Standards">
                <img src={ARCBPro} alt="" className="cards__item__img" />
            </figure>
           

            <div className="cards__item__info">
                <h4 className="cards__item__header">ARCB Professional Business Standards and Conduct</h4>
                 <h5 className="cards__item__text">Thus a Reflexologist communicates with an open and peaceful mind, while recognizing that the relationship between client and practitioner is a serious responsibility.


Therefore the professional Reflexologist working in the field shall:
<ol style={{margin: "1rem"}}>
                  <li>
                  	act in a professional and ethical manner at all times when interacting with other 
           
           Reflexologists and healthcare professionals.
                  </li>

                  <li>
                  	adhere to the 'ARCB® Professional Business Standards'.

                  </li>

               
                  <li>	promote the study, application and understanding of reflexology and promote the work of reflexology.
        
        The professional Reflexologist when seeing clients shall:
        </li>

        <li>
        	not infringe on any other professions’ scope of practice.
        </li>

        <li>	provide only the services for which professional qualifications have been met; accurately 
      
        representing competence, education, training and experience.
        </li>
       
       <li>
        not present oneself as a medical practitioner nor claim expertise or skill in any area of 
        
        knowledge or professional practice in which one has not been trained.
        </li>

        <li>
        	refer clients to appropriate medical or other healthcare professionals when appropriate.
        </li>

        <li>	use accepted medical/health terminology.
        </li>
      
      <li>	never prescribe, diagnose, prognosticate, treat for a specific illness or adjust medications.
        </li>

      <li>	endeavor to resolve any dispute that arises from professional interactions.
      </li>

      <li>ensure that anyone employed or working in the office shall also adhere to this Code of Ethics.
        
      </li>
        <br/>
        Furthermore the professional Reflexologist shall:
        
        <li>	approach every client with the same kind, ethical attitude.
        </li>

        <li>	strive to be objective, unbiased and truthful in order to maintain the utmost trust in the 
      
        client-practitioner relationship.
        </li>
        <li>	work within the client’s comfort zone and pain tolerance.
        </li>
                  
                  </ol></h5>
               
            </div>
            </div>
    </li>

</ul>

<ul className="cards__items" >
<li className="cards__item">
        <div className="cards__item__link" >
            <figure className="cards__item__pic-wrap" data-category="ARCB Ethics">
                <img src={ARCBEthics} alt="" className="cards__item__img" />
            </figure>
            <div className="cards__item__info">
                <h4 className="cards__item__header">ARCB Code of Ethics</h4>
                 <h5 className="cards__item__text">As a professional reflexologist who is certified and registered with the ARCB® I shall:
                 <ul style={{margin: "1rem"}}>
                 <li>

adhere to the ARCB® Code of Ethics and all other rules and regulations.
</li>
<li>
work within the guidelines of the federal, state and local laws including HIPPA.
</li>
<li>
not claim to cure or heal ailments or treat for any specific illnesses in marketing materials and verbal statements.
</li>
<li>
display my professional certificates in my office along with the ARCB® Code of Ethics.
</li>
<li>
display full and fair disclosure of services, a disclaimer, and a notice of complaint.
</li>
<li>

know a definition of reflexology, explaining what reflexology is and what the client can expect before or during the first session. When combining another discipline with reflexology clearly state that you are doing so.
</li>
<li>
communicate to the client the policy regarding fees, scheduling procedures, hours, the result of tardiness on the part of the client, and missed appointments.
</li>
<li>
have the client fill out a health history form.
</li>
<li>
have the client read and sign a contract for services form before the first session begins.
</li>
<li>
keep a record of clients’ names and contact information
</li>
<li>
keep financial records and bills in up-to-date.
</li>
<li>
keep the office area organized and neat. 
</li>
<li>
make a visual observation of the feet before beginning the session and adhere to contraindication guidelines.
</li>
<li>
document each session.
</li>
<li>
furnish a written receipt upon request.
</li>
<li>
wear suitable professional attire.
</li>
<li>
use clean linens for each client.
</li>
<li>
never take advantage of the client/practitioner relationship by establishing clear boundaries.
</li>
<li>
advertise in a proper and professional manner according to the ARCB Code of Ethics.
</li>
<li>
remain committed to continued reflexology education and training by attending classes, workshops, national conferences and/or other opportunities.
</li>
<li>
the use of tools and/or mechanical devices not approved by the FDA used in reflexology is discouraged due to legal liability. Their use falls outside the scope of ARCB® certification.
</li>
<li>
if found to have transgressed any rules of the ARCB professional business and ethical codes, voluntarily surrender and return to the ARCB® the ARCB® pin, certificate, I.D. card, and forgo the use of the title, “ARCB® Nationally Board Certified Reflexologist”.
</li>
</ul>
</h5>        
            </div>
        </div>
    </li>
</ul>

      </div>
    </div>
   </div>
   </>
  )
}

export default Cards