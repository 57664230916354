import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

import Footer from '../Footer';
import Fireflies from '../Fireflies';
import ReflexologySection from '../ReflexologySection';
import About from '../About-section'
import Book from '../Book';
import FloatingNav from '../FloatingNav';
import Map from '../Map';
import Scroll from '../Scroll';



export default function Home() {
  return (
   <>
  <HeroSection/>
  <FloatingNav/>
  <Book/>
  <ReflexologySection/>

  <Scroll/>
  <About/>


  <Map/>

  <Footer/>

   </>
  )
}

