import React from 'react'
import '../../App.css'
import FloatingNav from '../FloatingNav';
import {BsFillTelephoneFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import './Ref-Page.css';

function Reflexology() {
  return (
    <>
   <FloatingNav/>
  <div className="ref-pg">

    <h1 className="reflexology">What Is Reflexology?</h1>

    <div className="ref-pg-content">

      <h2>How often do people get Reflexology?</h2>
    <p>I learned to think of Reflexology as a complementary health practice to help your body, not as an alternative therapy. Your body will let you know how often to use Reflexology. People all wonder, how often do people get Reflexology?</p>
    <ul style={{margin: "2rem", marginTop: "1rem"}}>
      <li>Most People feel better following a Reflexology session within a few days.</li>
      <li>I learned we all function at a certain level of balance, during your session everything is getting more circulation, the nerves and blood supply. Most people feel calm and restful after a session. In class I learned the relaxation helps your body to normalize and balance.</li>
      <li>When you start a diet or exercise plan it takes a month or so to find your new balance.</li>
      <li>What kind of other things for self care are you doing? Walking regularly meditaion, yoga, seeing your Chiropractor or Osteopath, etc.. In class at Branch Reflexology Institute I learned twice a month Reflexology session is the normal time between sessions for high stress lifestyles where the client wants less bodily stress. Once a month Reflexology session is a tune-up and can pe part of your self-program. I tell people your body will tell you when to come back.</li>
    </ul>

    </div>

    <div className="ref-pg-content-2" >
    <h3>The Reflexologist does not do the "healing". The Reflexologist is there to give service by working both feet entirely, to help the client to reduce his/her stress so balance and normalization can occur naturally. </h3>
    </div>

    <div  className="ref-pg-paragraph">
      <h2>Three benefits of Reflexology:</h2>

      <ol style={{margin: "2rem", marginTop: ".5rem"}}>
          <li>Reflexolgy promotes balance and normalization of the body naturally.</li>
          <li>Reflexolgy reduces stress and brings about relaxation</li>
          <li>Reflexology improves circulation and the delivery of oxygen and nutrients to the cells</li>
      </ol>
    </div>

    <div className="ref-pg-content-2">
    <br/>
        <h3>A reflexologist does not diagnose or predict future health of a client. </h3>
        <h3>A reflexologist does not prescribe or recommend over the counter medications, vitamins or herbs. </h3>
        <br/>
        <h2>Theory of Practice:</h2>
        <p> Foot Reflexology is based on the premise that there are zones and reflex areas in the feet and hands which correspond to all body parts. </p>
    </div>



  <div style={{background: "rgb(247, 226, 199)", padding:".1rem"}}>
   <h1 style={{margin: "2rem",textAlign: "center", marginTop: "5rem"}}>Come and check out</h1> 
   <h1 style={{ fontFamily: "papyrus"}}>Sole Balance Reflexology</h1>

    <div style={{marginBottom: "12rem"}}>

  

    <div className="button-center" >
                  <Link className="cards__item__link" to="https://solebalancereflexology.setmore.com/bookappointment" target="_blank" style={{boxShadow: "none"}}>
                  <button className="cards__item__button" style={{fontSize: "1.3rem", margin: "auto", width: "10rem", marginTop: "15px"}}>Book Now 
                   <i className="fa fa-calendar" aria-hidden="true" style={{marginLeft: "10px"}}></i>
                   </button>
                  </Link>
                  </div>
                  <div style={{textAlign: "center", margin: "2rem"}}>
                  <h2>Call</h2>
    <h5 className="cards__item__text" style={{color: "#483C32", marginTop: "1rem", marginBottom: "1rem"}}> 
                        <BsFillTelephoneFill style={{color: "#483C32", marginRight: "1rem", marginBottom: "-5px"}}/>
                        (517) 914-0644
                    </h5>
                    </div>

                    <div className="button-center">
                  <Link  to='/maps' className="cards__item__button" style={{width: "10rem",  textDecoration: "none", textAlign: "center"}}>                  

                    Directions
               
                  </Link>
                  </div>
                  </div>

                  </div>
    </div>
    </>
  )
}

export default Reflexology