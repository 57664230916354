import React from 'react'
import FloatingNav from '../FloatingNav';
import Cards from '../Cards';

function Blog() {
  return (
   <>
  <FloatingNav/>
  
   <Cards><h1 className="blog" style={{color: "red"}}>Blog</h1></Cards>
   </>
  )
}

export default Blog