import React from 'react'
import CardItem from './CardItem'

import Reflexology from '../images/sole.png'
import "./Reflexology-section.css"

function ReflexologySection() {
  return (
    <>
    <div className="ref-section">
        <div className="ref-blur">
        <div className="ref-container">

        <h1 className="ref-header">What is Reflexology?</h1>


            <div className="left-side">
            <p className="ref-text">Reflexology, an intergrative health practice, maps a reflection of the body predominantly on the feet, hands and outer ears. It uses unique manual techniques to deliver pressure to neural pathways assisting the body to function optimally.<br/>
                ~ARCB</p>
                <p className="ref-text"> Reflexolgy is a science that deals with the principles that are reflexes in the feet and hands relative to every organ, gland and part of the body.</p>
            
                <a href="reflexology" alt="reflexology">
                    <button className="ref-btn">Learn More</button>
                </a>

            </div>
 

            <div className="ref-pic">
                    <img src={Reflexology} alt="reflexology" />
            </div>



</div>

</div>
<div className="border"></div>
    </div>

     </>
  )
}

export default ReflexologySection







