import React, {useState, useEffect} from 'react';
import Logo from "../images/Mom's-logo.png";
import {Link} from 'react-router-dom';
import {Button} from './Button';
import './Navbar.css'

export default function Navbar() {

const [click, setClick] = useState(false);
const [button, setButton] = useState(true);

const closeMobileMenu = () => setClick(false);
const handleClick = () => setClick(!click);

const showButton = () => {
  if(window.innerWidth <= 960) {
    setButton(false);
  
  } else {
    setButton(true);
  }
};

useEffect(() => {
  showButton();
}, []);

window.addEventListener('resize', showButton);

  return (
    <>
  <nav className="navbar">
    <div className="navbar-container">

        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
        <i className="fa fa-home fa-fw" aria-hidden="true"></i>
        <img src={Logo} alt="Logo" className=""/>
        
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fa fa-times' : 'fa fa-bars'} ></i>
        </div>

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>

        <li className='nav-item'>
              <Link 
                to='/' 
                className='nav-links'
                onClick={closeMobileMenu}>
               Home
              </Link>
            </li>

            <li className='nav-item'>
              <Link 
                to='/reflexology' 
                className='nav-links'
                onClick={closeMobileMenu}>
                What is Reflexology?
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links' 
                onClick={closeMobileMenu}>
                About
              </Link>
            </li>

            <li className='nav-item'>
              <Link 
                to='/blog' 
                className='nav-links' 
                onClick={closeMobileMenu}>
                  Blog
              </Link>
            </li>


            <li className='nav-item'>
              <Link
                to='https://solebalancereflexology.setmore.com/bookappointment'
                target="_blank"
                className='nav-links-mobile'
                onClick={closeMobileMenu}>
                  Book a Session
              </Link>
            </li>

        

        </ul>
        {button && 
          <Link  to='https://solebalancereflexology.setmore.com/bookappointment'
          target="_blank">
      <button className="nav-btn">
               Book a Session 
           <i className="fa fa-calendar" aria-hidden="true" style={{marginLeft: "10px"}}></i>
      </button>
   </Link>  }

    </div>
  </nav>
</>
  )
}


