import React from 'react'
import {Link} from 'react-router-dom';
import CardItem from './CardItem'

import Ad from '../images/Reflexology-small.png'
import Logo from '../images/ARCB-logo.png'
import './Cards.css'
import {BsFillTelephoneFill} from 'react-icons/bs';


function Book() {
  return (
    <>
<div style={{background: "rgb(29, 62, 29)"}}>

    <div className="cards" style={{background: "whitesmoke", borderTop: "10px solid rgb(29, 62, 29)"}}>

<div className="cards__container">
  <div className="cards__wrapper">
    <div className="Logo-div">
<img src={Logo} alt="" className="ARCB-logo" />
</div>

</div>
</div>


    <h1 className="header-2" style={{fontFamily: "verdana", fontSize: "2.5rem", width: "100%",  textAlign: "center"}}>Schedule Now</h1>
     <h1 className="header" style={{fontSize: "2.5rem", width: "100%",  paddingTop: "3rem", color: "rgb(43,90,43)", zIndex: "1"}}>Foot Reflexology Session</h1>
   
     <div className="cards__container" style={{marginTop: "-5rem"}}>
       <div className="cards__wrapper">

       <ul className="cards__items">

       <Link className="cards__item__link" to={"https://solebalancereflexology.setmore.com/bookappointment"} style={{margin: "auto"}}>
            <figure className="cards__item__pic-wrap" data-category={"Book a Foot Reflexology Session"}>
                <img src={Ad} alt="" className="cards__item__img"  />
            </figure>
        </Link>

        <Link className="cards__item__link" to={"https://solebalancereflexology.setmore.com/bookappointment"} >
        <div className="cards__item__info" style={{background: "white", borderRadius: "10px"}}>

                    <h2 style={{textAlign: "center",  color: "rgb(31, 86, 31)"}}>50 minute session $45</h2>

                    <h5 className="cards__item__header" style={{fontSize: "1.4rem", textAlign: "center", marginBottom: "10px"}}>Open Thursdays 1-5:30</h5>
                    <h5 className="cards__item__header" style={{fontSize: "1.5rem",  marginTop: "-5px"}}>Flexible schedule</h5>
                    
                    <h5 className="cards__item__text" >If the available times aren't convenient for you please call to shcedule an appointment.</h5>
                    <br/>

                    <h5 className="cards__item__text" style={{marginTop: "-1rem"}}> 
                        <BsFillTelephoneFill style={{color: "#483C32", marginRight: "1rem", marginBottom: "-5px"}}/>
                        (517) 914-0644
                    </h5>

                    <div className="button-center" >
                  <Link className="cards__item__link" to="https://solebalancereflexology.setmore.com/bookappointment" target="_blank" style={{boxShadow: "none"}}>
                  <button className="cards__item__button" style={{fontSize: "1.2rem", margin: "auto", marginTop: "10px"}}>Book Now</button>
                  </Link>
                  </div>

             </div>
             </Link>
          
           </ul>


        
             <div className="cards__item__info" style={{ height: "fit-content", border: "5px dashed green", background: "white"}}>
                 <h3 className="cards__item__header" style={{textAlign: "center", fontSize: "2rem"}}>Gift Certificate</h3>
                 <h2 style={{textAlign: "center", color: "rgb(31, 86, 31)", fontFamily: "papyrus"}}>Foot Reflexology Session</h2>
                 <h2 style={{textAlign: "center", lineHeight: "2", color: "rgb(31, 86, 31)"}}>Purchase in person</h2>
                
        
                  <h5 className="cards__item__text" style={{textAlign: "center"}}> 
                  <BsFillTelephoneFill style={{color: "#483C32", marginRight: "1rem"}}/>
                  (517) 914-0644
                  </h5>

                  <div className="button-center">
                  <Link  to='/maps' className="cards__item__button" style={{width: "10rem", margin: "auto", marginTop: "2rem", textDecoration: "none", textAlign: "center"}}>                  

                    Directions
               
                  </Link>
                  </div>
       </div>
      
     
     </div>
     </div>

     <div style={{marginLeft: "30%", marginBottom: "3rem"}}>
     <div style={{width: "10rem", height: "10rem", borderRadius: "50%", border: "5px solid tan"}}
    ></div>
     <div style={{width: "8rem", height: "8rem", borderRadius: "50%", border: "5px solid tan", marginTop: "-10rem", marginLeft: "-5rem"}}
    ></div>
       <div style={{width: "8rem", height: "8rem", borderRadius: "50%", border: "5px solid tan", marginTop: "-12rem", marginLeft: "3rem"}}
    ></div>
    </div>

     </div>
     </div>
     </>
  )
}

export default Book;

