import React from 'react'
import '../../App.css'
import FloatingNav from '../FloatingNav';
import Profile from '../../images/Mom-profile-pic.png'
import './About.css';
import ARCB from '../../images/ARCB-card.png'
import Logo from '../../images/ARCB-logo.png'
import { Link } from 'react-router-dom';


export default function About() {
  return (
   <>
 <FloatingNav/>
 <div className="about-pg-header">
      <div className="about-background-blur">

          <h1 className="about-pg-title">
          About Janet
          </h1>

<div className="about">
          <div className="about-image" style={{marginTop: "-4rem"}}>
              <img src={Profile} alt="Janet Goodell" className="about-pic"/>
           </div>
</div>

      </div>
   </div>

   <div className="welcome">
      <h1 style={{marginBottom: "1rem",  textShadow: "4px 2px 3px purple"}}>Welcome to my Reflexology business</h1>
      <p style={{ fontSize: "1.5rem", color: "rgb(241, 227, 252)", textShadow: "1px 1px 1px purple"}}>I believe reflexology helps people. I spent my working years in a helping profession.  In 2017 I tried reflexology and thought…wow I want to learn how to do this, it went in my “bucket list”.
              Now you can find me on Thursdays in downtown Manchester offering reflexology sessions.  My aim with reflexology is to help relax you, so that your body can regain balance.</p>

              <div className='about-pg-button' >
          <Link  to='https://solebalancereflexology.setmore.com/bookappointment'
                  target="_blank">
              <button className="about-pg-btn">
                     Schedule a Session
                  <i className="fa fa-calendar" aria-hidden="true" style={{marginLeft: "10px"}}></i>
              </button>
          </Link>
      </div>
   </div>

   <div className="ARCB">
      <h1 style={{ textShadow: "4px 2px 3px rgb(29, 62, 29)", marginBottom: "1rem"}}>I am nationally certified with ARCB</h1>
      <p style={{ fontSize: "1.5rem", textShadow: "1px 1px 1px rgb(29, 62, 29)"}}> We are taught deep thumb walking techniques over reflexes, on the plantar, dorsal, medial, and lateral aspects of your feet. Reflexology helps you to relax, increases circulation, and helps your body reboot or balance.</p>
      <div className="about-pg-image">
        <img src={Logo} alt="ARCB logo" className="about-arcb-logo"/>
      <img src={ARCB} alt="ARCB Card" className="about-pg-pic"/>
      </div>
   </div>
   </>
  )
}

