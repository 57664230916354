import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';
import './Button.css';
import {BsFillTelephoneFill, BsFacebook} from 'react-icons/bs';
import VideoBG from '../images/moms-mov-bg.mov'
import Scale from '../images/scale.png'



export default function HeroSection() {
  
 

  return (


<div className="hero-container">

{/*  <video className='video-tag' autoPlay loop muted>
      <source src={VideoBG} type='video/mp4' />
  </video>
*/}

<div className="hero-content">
  <h1 className="hero-header" style={{marginTop: "1rem"}}>SOLE BALANCE</h1>
  <h2 className="header-2">Reflexology</h2>
  <div className='hero-btns'>
  <Link  to='https://solebalancereflexology.setmore.com/bookappointment'
          target="_blank">
      <button className="btns">
               Book A Session
           <i className="fa fa-calendar" aria-hidden="true" style={{marginLeft: "10px"}}></i>
      </button>
   </Link>
  </div>
 
   </div>


   <div className="contact" >

      <article className="phone">

<ul className="call">
  <li style={{textAlign: "center"}}>

  
  <h2 className="call-header" >

Call / Text</h2>
<h5 className="cards__item__text" > 
                  <BsFillTelephoneFill style={{color: "#483C32", marginRight: "1rem", marginBottom: "-5px"}}/>
                  (517) 914-0644
              </h5>



               <h4 className="call-header-2" >Very flexible schedule!</h4>
               <h4 className="call-text"> If the available times aren't conveinent for you please call to shcedule an appointment.</h4>
  </li>
</ul>
      </article>
          
  </div>
 
</div>

  )
}
